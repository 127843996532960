import React from 'react'

import {
  useBreakpointValue,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Spacer,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'

import { RepeatIcon } from '@chakra-ui/icons'

import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  LineChart,
  Tooltip
} from 'recharts'

import {
  clusterApiUrl,
  Connection,
  PublicKey
} from '@solana/web3.js'

import otterLogoFull from './icons/otterlogo_full.svg'
import otterLogoGlyph from './icons/otterlogo_glyph.svg'

const RELAYER_PUBLIC_KEY = new PublicKey('9S8aH8LXtDbPGS5z9dnJWSmwAAjx1AFpQsjBhi8eidnc')
const connection = new Connection(clusterApiUrl('mainnet-beta'))

interface ScalarMetricProps {
  text: string
  value: null | number
  styleProps?: {}
}
function ScalarMetric (props: ScalarMetricProps) {
  let subtextContent
  if (props.value) {
    subtextContent = (
      <Text
        fontWeight="800"
        fontSize={['1.4em', '2em']}
        mt="2%"
      >
        {props.value}
      </Text>
    )
  } else {
    subtextContent = (
      <Spinner
        size="lg"
        color="bistre"
        mt="11%"
      />
    )
  }
  return (
    <Box
      m={['3%', '1%']}
      p={['2% 3% 2% 3%', '1% 2% 1% 2%']}
      border="1px"
      borderRadius="4px"
      boxShadow="2xl"
      {...props.styleProps}
    >
      <Text fontSize={['0.8em', '1em']}>
        {props.text}
      </Text>
      <Box>
        {subtextContent}
      </Box>
    </Box>
  )
}

interface EntireState {
  aggregateStats?: {}
  allTransactionsDetailed?: any[]
  relayerBalance?: null | number
  currentTps?: null | number
  refreshCallback: () => void
}

function ScalarMetricsMain (props: EntireState) {
  return (
    <Flex mt="2%">
      <ScalarMetric
        text="Total Deposits"
        value={
          props.aggregateStats.totalDeposits ? '◎' + props.aggregateStats.totalDeposits : null
        }
      />
      <ScalarMetric
        text="Total Withdraws"
        value={
          props.aggregateStats.totalWithdraws ? '◎' + props.aggregateStats.totalWithdraws : null
        }
      />
      <ScalarMetric
        text="Total Value Locked"
        value={
          props.aggregateStats.totalValueLocked
            ? '$' + (props.aggregateStats.totalValueLocked / 100).toLocaleString(
              undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )
            : null
        }
      />
      <ScalarMetric
        text="Total Volume"
        value={
          props.aggregateStats.totalVolume
            ? '$' + (props.aggregateStats.totalVolume / 100).toLocaleString(
              undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )
            : null
        }
      />
    </Flex>
  )
}

function ScalarMetricsSecondary (props: EntireState) {
  return (
    <Flex mt={['1%', '3%']}>
      <ScalarMetric
        text="Total Deposits: Non-Dev"
        value={
          props.aggregateStats.totalDepositsExceptExcluded
            ? '◎' + props.aggregateStats.totalDepositsExceptExcluded
            : null
        }
      />
      <ScalarMetric
        text="Total Withdraws: Non-Dev"
        value={
          props.aggregateStats.totalWithdrawsExceptExcluded
            ? '◎' + props.aggregateStats.totalWithdrawsExceptExcluded
            : null
        }
      />
      <ScalarMetric
        text="Total Withdraws: Clean"
        value={
          props.aggregateStats.totalWithdrawsClean
            ? '◎' + props.aggregateStats.totalWithdrawsClean
            : null
        }
      />
      <ScalarMetric
        text="Total Deposit Init"
        value={
          props.aggregateStats.totalDepositInit ? props.aggregateStats.totalDepositInit : null
        }
      />
      <ScalarMetric
        text="Total Withdraw Init"
        value={
          props.aggregateStats.totalWithdrawInit ? props.aggregateStats.totalWithdrawInit : null
        }
      />
      <ScalarMetric
        text="Unique Deposit Addresses"
        value={
          props.aggregateStats.totalUniqueDepositAddresses
            ? props.aggregateStats.totalUniqueDepositAddresses
            : null
        }
      />
      <ScalarMetric
        text="Unique Withdraw Addresses"
        value={
          props.aggregateStats.totalUniqueWithdrawAddresses
            ? props.aggregateStats.totalUniqueWithdrawAddresses
            : null
        }
      />
      <ScalarMetric
        text="Relayer Balance"
        value={
          props.relayerBalance
            ? '◎' + (props.relayerBalance / 1e9).toLocaleString(
              undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }
            )
            : null
        }
        styleProps={
          props.relayerBalance !== null && props.relayerBalance < 2 * 1e9 ? { bg: 'red' } : {}
        }
      />
      <ScalarMetric
        text="Current TPS"
        value={
          props.currentTps
            ? 10 * Math.round(props.currentTps / 10)
            : null
        }
      />
    </Flex>
  )
}

function CumulativeDepositAndWithdrawGraph (props: EntireState) {
  if (props.allTransactionsDetailed.length === 0) {
    return (
      <Center>
        <Spinner
          size="xl"
          color="bistre"
          mt="4%"
        />
      </Center>
    )
  }

  const secondsPerBucket = 4 * 60 * 60
  const allBlockTimes = props.allTransactionsDetailed.map(tx => tx.blockTime)
  const minBlockTime = Math.min(...allBlockTimes)
  const maxBlockTime = Math.max(...allBlockTimes)
  const numBuckets = Math.ceil((maxBlockTime - minBlockTime) / secondsPerBucket)

  const numDepositsPerBucket = Array(numBuckets).fill(0)
  const numWithdrawsPerBucket = Array(numBuckets).fill(0)

  props.allTransactionsDetailed.forEach((tx) => {
    const bucketIdx = Math.floor((tx.blockTime - minBlockTime) / secondsPerBucket)
    if (tx.isDeposit) {
      numDepositsPerBucket[bucketIdx] += 1
    } else {
      numWithdrawsPerBucket[bucketIdx] += 1
    }
  })

  const numDepositsPerBucketCumSum = [numDepositsPerBucket[0]]
  const numWithdrawsPerBucketCumSum = [numWithdrawsPerBucket[0]]

  for (let i = 1; i < numBuckets; i++) {
    numDepositsPerBucketCumSum.push(
      numDepositsPerBucketCumSum[i - 1] + numDepositsPerBucket[i]
    )
    numWithdrawsPerBucketCumSum.push(
      numWithdrawsPerBucketCumSum[i - 1] + numWithdrawsPerBucket[i]
    )
  }

  const data = []
  for (let i = 0; i < numBuckets; i++) {
    const date = new Date((minBlockTime + secondsPerBucket * i) * 1000)
    data.push({
      datetime: date.toLocaleDateString() + ' ' + date.toLocaleTimeString(),
      totalDeposits: numDepositsPerBucketCumSum[i],
      totalWithdraws: numWithdrawsPerBucketCumSum[i]
    })
  }

  return (
    <Box mt={['10%', '4%']}>
      <Center>
        Cumulative Deposits and Withdraws
      </Center>
      <Center mt={['5%', '1%']}>
        <AreaChart
          width={useBreakpointValue({ base: window.innerWidth, sm: 1000 })}
          height={useBreakpointValue({ base: window.innerWidth / 2, sm: 400 })}
          data={data}
          margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid
            stroke="#3f2f2750"
            strokeDasharray="3 3"
          />
          <XAxis dataKey="datetime" tick={false} />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="totalDeposits"
            stroke="#90cba8"
            fill="#90cba8"
          />
          <Area
            type="monotone"
            dataKey="totalWithdraws"
            stroke="#84b4d9"
            fill="#84b4d9"
          />
        </AreaChart>
      </Center>
    </Box>
  )
}

function EachDepositAndWithdrawGraph (props: EntireState) {
  if (props.allTransactionsDetailed.length === 0) {
    return (
      <Center>
        <Spinner
          size="xl"
          color="bistre"
          mt="4%"
        />
      </Center>
    )
  }

  const secondsPerBucket = 24 * 60 * 60
  const allBlockTimes = props.allTransactionsDetailed.map(tx => tx.blockTime)
  const minBlockTime = Math.min(...allBlockTimes)
  const maxBlockTime = Math.max(...allBlockTimes)
  const numBuckets = Math.ceil((maxBlockTime - minBlockTime) / secondsPerBucket)

  const numDepositsPerBucket = Array(numBuckets).fill(0)
  const numWithdrawsPerBucket = Array(numBuckets).fill(0)

  props.allTransactionsDetailed.forEach((tx) => {
    const bucketIdx = Math.floor((tx.blockTime - minBlockTime) / secondsPerBucket)
    if (tx.isDeposit) {
      numDepositsPerBucket[bucketIdx] += 1
    } else {
      numWithdrawsPerBucket[bucketIdx] += 1
    }
  })

  const data = []
  for (let i = 0; i < numBuckets; i++) {
    const date = new Date((minBlockTime + secondsPerBucket * i) * 1000)
    data.push({
      datetime: date.toLocaleDateString() + ' ' + date.toLocaleTimeString(),
      totalDeposits: numDepositsPerBucket[i],
      totalWithdraws: numWithdrawsPerBucket[i]
    })
  }

  return (
    <Box mt={['10%', '4%']}>
      <Center>
        Daily Deposits and Withdraws
      </Center>
      <Center mt={['5%', '1%']}>
        <BarChart
          width={useBreakpointValue({ base: window.innerWidth, sm: 1000 })}
          height={useBreakpointValue({ base: window.innerWidth / 2, sm: 400 })}
          data={data}
          margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid
            stroke="#3f2f2750"
            strokeDasharray="3 3"
          />
          <XAxis dataKey="datetime" tick={false} />
          <YAxis />
          <Tooltip />
          <Bar
            type="monotone"
            dataKey="totalDeposits"
            stroke="#90cba8"
            fill="#90cba8"
          />
          <Bar
            type="monotone"
            dataKey="totalWithdraws"
            stroke="#84b4d9"
            fill="#84b4d9"
          />
        </BarChart>
      </Center>
    </Box>
  )
}

function LatencyGraph (props: EntireState) {
  if (props.allTransactionsDetailed.length === 0) {
    return (
      <Center>
        <Spinner
          size="xl"
          color="bistre"
          mt="4%"
        />
      </Center>
    )
  }

  let data = []
  props.allTransactionsDetailed.forEach((tx) => {
    if (tx.latency === null) {
      return
    }
    const date = new Date(tx.blockTime * 1000)
    if (tx.isDeposit) {
      data.push({
        blocktime: tx.blockTime,
        datetime: date.toLocaleDateString() + ' ' + date.toLocaleTimeString(),
        depositLatency: tx.latency
      })
    } else {
      data.push({
        blocktime: tx.blockTime,
        datetime: date.toLocaleDateString() + ' ' + date.toLocaleTimeString(),
        withdrawLatency: tx.latency
      })
    }
  })

  data.sort((a, b) => a.blocktime - b.blocktime)

  // Throw away all data outside of 30 minutes.
  const OUTLIER_THRESHOLD = 30 * 60
  const isOutlier = (latency) =>
    latency.depositLatency > OUTLIER_THRESHOLD || latency.withdrawLatency > OUTLIER_THRESHOLD
  const outliers = data.filter(isOutlier)
  data = data.filter((latency) => !isOutlier(latency))

  const depositData = data.filter(entry => entry.depositLatency !== undefined)
  const withdrawData = data.filter(entry => entry.withdrawLatency !== undefined)

  const WINDOW_SIZE = 10
  const smoothedData = []
  depositData.forEach((entry, i) => {
    let dataWindow = depositData.slice(
      Math.max(0, i - WINDOW_SIZE),
      Math.min(depositData.length, i + WINDOW_SIZE)
    ).map((entry) => entry.depositLatency)
    dataWindow = dataWindow
      .sort()
      .slice(Math.round(dataWindow.length / 10), Math.round(9 * dataWindow.length))
    const smoothedLatency = Math.round(dataWindow.reduce((a, b) => a + b, 0) / dataWindow.length)
    smoothedData.push({
      blocktime: entry.blocktime,
      datetime: entry.datetime,
      depositLatency: smoothedLatency
    })
  })
  withdrawData.forEach((entry, i) => {
    let dataWindow = withdrawData.slice(
      Math.max(0, i - WINDOW_SIZE),
      Math.min(withdrawData.length, i + WINDOW_SIZE)
    ).map((entry) => entry.withdrawLatency)
    dataWindow = dataWindow
      .sort()
      .slice(Math.round(dataWindow.length / 10), Math.round(9 * dataWindow.length))
    const smoothedLatency = Math.round(dataWindow.reduce((a, b) => a + b, 0) / dataWindow.length)
    smoothedData.push({
      blocktime: entry.blocktime,
      datetime: entry.datetime,
      withdrawLatency: smoothedLatency
    })
  })

  smoothedData.sort((a, b) => a.blocktime - b.blocktime)

  return (
    <Box mt={['10%', '4%']}>
      <Center>
        Latency of Init to Finalize
      </Center>
      <Center mt="1%">
        <LineChart
          width={useBreakpointValue({ base: window.innerWidth, sm: 1000 })}
          height={useBreakpointValue({ base: window.innerWidth / 2, sm: 400 })}
          data={data}
          margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid
            stroke="#3f2f2750"
            strokeDasharray="3 3"
          />
          <XAxis dataKey="datetime" tick={false} />
          <YAxis />
          <Tooltip />
          <Line
            type="linear"
            dataKey="depositLatency"
            stroke="#90cba8"
            connectNulls={true}
            dot={false}
          />
          <Line
            type="linear"
            dataKey="withdrawLatency"
            stroke="#84b4d9"
            connectNulls={true}
            dot={false}
          />
        </LineChart>
      </Center>
      <Center mt="1%">
        Outliers that are not shown:
      </Center>
      {outliers.map((outlier, i) => {
        return (
          <Text key={i}>
            {`${outlier.depositLatency || outlier.withdrawLatency} second ${outlier.depositLatency ? 'deposit' : 'withdraw'} on ${outlier.datetime}`}
          </Text>
        )
      })}
      <Center mt="1%">
        Latency of Init to Finalize, Rolling Average of the Middle 80th Percentile
      </Center>
      <Center mt="1%">
        <LineChart
          width={useBreakpointValue({ base: window.innerWidth, sm: 1000 })}
          height={useBreakpointValue({ base: window.innerWidth / 2, sm: 400 })}
          data={smoothedData}
          margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid
            stroke="#3f2f2750"
            strokeDasharray="3 3"
          />
          <XAxis dataKey="datetime" tick={false} />
          <YAxis />
          <Tooltip />
          <Line
            type="linear"
            dataKey="depositLatency"
            stroke="#90cba8"
            connectNulls={true}
            dot={false}
          />
          <Line
            type="linear"
            dataKey="withdrawLatency"
            stroke="#84b4d9"
            connectNulls={true}
            dot={false}
          />
        </LineChart>
      </Center>
    </Box>
  )
}

function ListOfWhales (props: EntireState) {
  if (props.allTransactionsDetailed.length === 0) {
    return null
  }

  const walletToBalanceDeposit = {}
  const walletToCountDeposit = {}
  const walletToBalanceWithdraw = {}
  const walletToCountWithdraw = {}

  props.allTransactionsDetailed.forEach(tx => {
    if (tx.isDeposit) {
      if (walletToBalanceDeposit[tx.userAddress] === undefined) {
        walletToBalanceDeposit[tx.userAddress] = tx.preBalance
      } else {
        walletToBalanceDeposit[tx.userAddress] = Math.max(
          walletToBalanceDeposit[tx.userAddress],
          tx.preBalance
        )
      }
      if (walletToCountDeposit[tx.userAddress] === undefined) {
        walletToCountDeposit[tx.userAddress] = 1
      } else {
        walletToCountDeposit[tx.userAddress] += 1
      }
    } else {
      if (walletToBalanceWithdraw[tx.userAddress] === undefined) {
        walletToBalanceWithdraw[tx.userAddress] = tx.preBalance
      } else {
        walletToBalanceWithdraw[tx.userAddress] = Math.max(
          walletToBalanceWithdraw[tx.userAddress],
          tx.preBalance
        )
      }
      if (walletToCountWithdraw[tx.userAddress] === undefined) {
        walletToCountWithdraw[tx.userAddress] = 1
      } else {
        walletToCountWithdraw[tx.userAddress] += 1
      }
    }
  })

  const walletAndBalanceDeposit = []
  const walletAndCountDeposit = []
  const walletAndBalanceWithdraw = []
  const walletAndCountWithdraw = []
  for (const userAddress in walletToBalanceDeposit) {
    walletAndBalanceDeposit.push([userAddress, walletToBalanceDeposit[userAddress]])
  }
  for (const userAddress in walletToCountDeposit) {
    walletAndCountDeposit.push([userAddress, walletToCountDeposit[userAddress]])
  }
  for (const userAddress in walletToBalanceWithdraw) {
    walletAndBalanceWithdraw.push([userAddress, walletToBalanceWithdraw[userAddress]])
  }
  for (const userAddress in walletToCountWithdraw) {
    walletAndCountWithdraw.push([userAddress, walletToCountWithdraw[userAddress]])
  }

  const balanceDepositWhales = walletAndBalanceDeposit
    .sort((a, b) => b[1] - a[1])
    .slice(0, 8)
  const countDepositWhales = walletAndCountDeposit
    .sort((a, b) => b[1] - a[1])
    .slice(0, 8)
  const balanceWithdrawWhales = walletAndBalanceWithdraw
    .sort((a, b) => b[1] - a[1])
    .slice(0, 8)
  const countWithdrawWhales = walletAndCountWithdraw
    .sort((a, b) => b[1] - a[1])
    .slice(0, 8)

  interface WhalesProps {
    title: string
    data: any[]
  }
  function WhalesByBalance (props: WhalesProps) {
    return (
      <Box
        w={['90%', '30%']}
        m={['0 0 10% 0', '0 2% 5% 2%']}
        p={['4% 6% 4% 6%', '1% 2% 1% 2%']}
        border="1px"
        borderRadius="4px"
        boxShadow="2xl"
      >
        <Text>
          {props.title}
        </Text>
        <VStack mt="2%">
          {props.data.map((addressBalance, i) =>
            <Link
              href={'https://explorer.solana.com/address/' + addressBalance[0]}
              isExternal
              key={i}
              w={['100%', '80%']}
            >
              <Flex>
                <Text fontFamily="Courier">
                  {addressBalance[0].slice(0, 12) + '...' + addressBalance[0].slice(-3)}
                </Text>
                <Text>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Text>
                <Text>
                  {'◎' + (addressBalance[1] / 1e9).toFixed(2)}
                </Text>
              </Flex>
            </Link>
          )}
        </VStack>
      </Box>
    )
  }

  function WhalesByCount (props: WhalesProps) {
    return (
      <Box
        w={['90%', '30%']}
        m={['0 0 10% 0', '0 2% 5% 2%']}
        p={['4% 6% 4% 6%', '1% 2% 1% 2%']}
        border="1px"
        borderRadius="4px"
        boxShadow="2xl"
      >
        <Text>
          {props.title}
        </Text>
        <VStack mt="2%">
          {props.data.map((addressNumDeposits, i) =>
            <Link
              href={'https://explorer.solana.com/address/' + addressNumDeposits[0]}
              isExternal
              key={i}
              w={['100%', '80%']}
            >
              <Flex>
                <Text fontFamily="Courier">
                  {addressNumDeposits[0].slice(0, 12) + '...' + addressNumDeposits[0].slice(-3)}
                </Text>
                <Text>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Text>
                <Text>
                  {addressNumDeposits[1]}
                </Text>
              </Flex>
            </Link>
          )}
        </VStack>
      </Box>
    )
  }

  return (
    <Center
      mt="5%"
      w="100%"
    >
      <WhalesByBalance
        title="Deposit Whales by Balance"
        data={balanceDepositWhales}
      />
      <WhalesByCount
        title="Deposit Whales by Count"
        data={countDepositWhales}
      />
      <WhalesByBalance
        title="Withdraw Whales by Balance"
        data={balanceWithdrawWhales}
      />
      <WhalesByCount
        title="Withdraw Whales by Count"
        data={countWithdrawWhales}
      />
    </Center>
  )
}

function MetricsPage (props: EntireState) {
  const logoComponent = (
    <Box
      w={['28%', '18%']}
      m={['6% 0 6% 0', '1.5% 0 0.5% 0']}
    >
      <Image src={useBreakpointValue({ base: otterLogoGlyph, sm: otterLogoFull })} />
    </Box>
  )
  const titleComponent = (
    <Heading
      fontSize={['1.5em', '2.5em']}
    >
      Otter Metrics
    </Heading>
  )
  const refreshButtonComponent = (
    <Box ml="7%">
      <Button
        leftIcon={<RepeatIcon />}
        onClick={(e) => props.refreshCallback()}
        backgroundColor="etonblue"
        border="1px"
        _hover={{
          filter: 'brightness(95%)'
        }}
      >
        Refresh
      </Button>
    </Box>
  )
  const header = useBreakpointValue({
    base: (
      <>
        <Flex m="0 5% 0 6%">
          {logoComponent}
          <Spacer />
          {refreshButtonComponent}
        </Flex>
        {titleComponent}
      </>
    ),
    sm: (
      <Flex m="0 5% 0 3%">
        {logoComponent}
        <Spacer />
        {titleComponent}
        <Spacer />
        {refreshButtonComponent}
      </Flex>
    )
  })
  return (
    <Box minH="100vh">
      {header}
      <Box
        m={['0 2% 10% 2%', '0 10% 10% 10%']}
      >
        <ScalarMetricsMain
          aggregateStats={props.aggregateStats}
        />
        <CumulativeDepositAndWithdrawGraph
          allTransactionsDetailed={props.allTransactionsDetailed}
        />
        <ScalarMetricsSecondary
          aggregateStats={props.aggregateStats}
          relayerBalance={props.relayerBalance}
          currentTps={props.currentTps}
        />
        <EachDepositAndWithdrawGraph
          allTransactionsDetailed={props.allTransactionsDetailed}
        />
        <LatencyGraph
          allTransactionsDetailed={props.allTransactionsDetailed}
        />
        <ListOfWhales
          allTransactionsDetailed={props.allTransactionsDetailed}
        />
        </Box>
    </Box>
  )
}

export default class Metrics extends React.Component {
  constructor () {
    super()
    this.state = {
      allTransactionsDetailed: [],
      aggregateStats: {},
      relayerBalance: null,
      currentTps: null
    }
    this.fetchMetrics = this.fetchMetrics.bind(this)
  }

  componentDidMount () {
    this.fetchMetrics()
  }

  fetchMetrics () {
    this.setState({
      allTransactionsDetailed: [],
      aggregateStats: {},
      relayerBalance: null,
      currentTps: null
    })
    fetch('https://statistics.otter.cash/allTransactionsDetailed')
      .then(resp => resp.json())
      .then(resp => this.setState({ allTransactionsDetailed: resp }))
    fetch('https://statistics.otter.cash/aggregateStats')
      .then(resp => resp.json())
      .then(resp => this.setState({ aggregateStats: resp }))
    const refreshMetricsFromCluster = () => {
      connection.getBalance(RELAYER_PUBLIC_KEY)
        .then(resp => this.setState({ relayerBalance: resp }))
      connection.getRecentPerformanceSamples(10)
        .then(resp => {
          const sortedNumTxs = resp
            .map(sample => sample.numTransactions / sample.samplePeriodSecs)
            .sort((a, b) => a - b)
          const currentTps = sortedNumTxs[Math.floor(sortedNumTxs.length / 2) - 1]
          this.setState({ currentTps })
        })
    }
    refreshMetricsFromCluster()
    setInterval(refreshMetricsFromCluster, 10000)
  }

  render () {
    return (
      <MetricsPage
        aggregateStats={this.state.aggregateStats}
        allTransactionsDetailed={this.state.allTransactionsDetailed}
        relayerBalance={this.state.relayerBalance}
        currentTps={this.state.currentTps}
        refreshCallback={this.fetchMetrics}
      />
    )
  }
}
