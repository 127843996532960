import React from 'react'
import ReactDOM from 'react-dom'
import {
  ChakraProvider,
  extendTheme
} from '@chakra-ui/react'

import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'

import Metrics from './Metrics'

const theme = extendTheme({
  colors: {
    peach: '#ffcd9e',
    champagne: '#f4e6ab',
    teagreen: '#e8ffb8',
    bistre: '#3f2f27',
    etonblue: '#90cba8',
    aero: '#84b4d9'
  },
  fonts: {
    button: 'Montserrat'
  },
  styles: {
    global: {
      body: {
        fontFamily: 'Montserrat',
        fontWeight: '400',
        color: 'bistre',
        bg: 'white',
        bgGradient: 'linear(150deg, peach, teagreen)',
        textAlign: 'center'
      },
      div: {
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
      }
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Metrics />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
